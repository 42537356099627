import {
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";

const checkoutSchema = yup.object().shape({
  depense: yup.string().required("Champ requis"),
  date: yup.string().required("Champ requis"),
});

const initialValues = {
  depense: "",
  date: "",
  timestamp: new Date().toISOString(),
};

const DonneesDepenseAdd = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activite_id = searchParams.get("activite_id");
  const projet_id = searchParams.get("projet_id");
  const titre = searchParams.get("titre");

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const activite = async (values, onSubmitProps) => {
    const formDataActivite = {
      depense: values.depense,
      date: values.date,
      activite_id: activite_id,
      projet_id: projet_id,
      norme: values.norme,
    };

    const response = await axios.post(
      "https://vincentlor-dev.fr/server/data_budget_activite",
      {
        data: formDataActivite,
      }
    );
    const success = response.status === 200;
    if (success) {
      navigate({
        pathname: "/donnees/activites/",
        search: createSearchParams({
          id: projet_id,
        }).toString(),
      });
    } else {
      setErrorMessage("Erreur lors de la validation");
      animate();
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await activite(values, onSubmitProps);
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header
            title={"Données de dépense de l'activité : " + titre}
            subtitle="Ajouter des données de dépense"
          />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Dépense"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.depense}
                    name="depense"
                    error={!!touched.depense && !!errors.depense}
                    helperText={touched.depense && errors.depense}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="date"
                    value={values.date}
                    error={!!touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    min="0"
                    max="100"
                    label="Norme"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.norme}
                    name="norme"
                    error={!!touched.norme && !!errors.norme}
                    helperText={touched.norme && errors.norme}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Valider
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </main>
    </div>
  );
};

export default DonneesDepenseAdd;
