import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Grid,
  Tooltip,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import BarChartAvancement from "../../components/BarChartAvancement";
import BarChartDepense from "../../components/BarChartDepense";
import axios from "axios";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [projet0, setProjet0] = useState(null);
  const [projet1, setProjet1] = useState(null);
  const [projet2, setProjet2] = useState(null);
  const [projet3, setProjet3] = useState(null);
  const [projet4, setProjet4] = useState(null);
  const [activites0, setActivites0] = useState([]);
  const [activites1, setActivites1] = useState([]);
  const [activites2, setActivites2] = useState([]);
  const [activites3, setActivites3] = useState([]);
  const [activites4, setActivites4] = useState([]);

  function compareByDate(a, b) {
    if (a.dateDebut < b.dateDebut) {
      return -1;
    }
    if (a.dateDebut > b.dateDebut) {
      return 1;
    }
    return 0;
  }

  const getActivites = async (id, index) => {
    const res = await axios.get(
      "https://vincentlor-dev.fr/server/activites/" + id
    );
    const activitesArray = [];
    const dataObjectActivite = res.data.data;
    const arrayOfKeysActivite = Object.keys(dataObjectActivite);
    const arrayOfDataActivite = Object.keys(dataObjectActivite).map(
      (key) => dataObjectActivite[key]
    );
    arrayOfKeysActivite.forEach(async (key, index) => {
      const formattedData = { ...arrayOfDataActivite[index] };
      formattedData["documentId"] = key;
      activitesArray.push(formattedData);
    });

    const d = activitesArray.sort(compareByDate);

    switch (index) {
      case 0:
        setActivites0(d);
        break;
      case 1:
        setActivites1(d);
        break;
      case 2:
        setActivites2(d);
        break;
      case 3:
        setActivites3(d);
        break;
      case 4:
        setActivites4(d);
        break;
      default:
        break;
    }
    //return activitesArray;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://vincentlor-dev.fr/server/projets"
      );

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);

      await arrayOfKeys.forEach(async (key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        const activites = await getActivites(key, index);
        switch (index) {
          case 0:
            setProjet0(formattedData);
            break;
          case 1:
            setProjet1(formattedData);
            break;
          case 2:
            setProjet2(formattedData);
            break;
          case 3:
            setProjet3(formattedData);
            break;
          case 4:
            setProjet4(formattedData);
            break;
          default:
            break;
        }
      });
    }
    fetchData();
  }, []);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box
          sx={{
            margin: {
              xs: "0px",
              lg: "20px",
            },
          }}
        >
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header
              title="DASHBOARD"
              subtitle="Bienvenue sur votre dashboard"
            />

            <Box>
              {/*   <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Télécharger Rapport
            </Button> */}
            </Box>
          </Box>

          {/* GRID & CHARTS */}
          {projet0 && (
            <Grid container spacing={2} marginBottom="20px">
              {/* ROW 2 */}
              <Grid item xs={12} backgroundColor={colors.primary[400]}>
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      {projet0.titre}
                    </Typography>
                  </Box>
                  <Box>
                    {projet0.imagePath && (
                      <Tooltip title="Image de l'avancement">
                        <a
                          href={`https://vincentlor-dev.fr/images_suiviprojet/${projet0.imagePath}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadOutlinedIcon
                            sx={{
                              fontSize: "26px",
                              color: colors.greenAccent[500],
                            }}
                          />
                        </a>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Box height="250px" m="-20px 0 0 0">
                  <LineChart
                    isDashboard={true}
                    projet_id={projet0.documentId}
                    date_debut={projet0.dateDebut}
                    date_fin={projet0.dateFin}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {/* ROW 3 */}
          {activites0 &&
            activites0.map((activite, index) => (
              <Grid container spacing={0} marginBottom="20px">
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Avancement {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartAvancement
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartAvancement>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Dépense {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartDepense
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartDepense>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}

          {projet1 && (
            <Grid container spacing={2} marginBottom="20px">
              {/* ROW 2 */}
              <Grid item xs={12} backgroundColor={colors.primary[400]}>
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      {projet1.titre}
                    </Typography>
                  </Box>
                  <Box>
                    {projet1.imagePath && (
                      <Tooltip title="Image de l'avancement">
                        <a
                          href={`https://vincentlor-dev.fr/images_suiviprojet/${projet1.imagePath}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadOutlinedIcon
                            sx={{
                              fontSize: "26px",
                              color: colors.greenAccent[500],
                            }}
                          />
                        </a>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Box height="250px" m="-20px 0 0 0">
                  <LineChart
                    isDashboard={true}
                    projet_id={projet1.documentId}
                    date_debut={projet1.dateDebut}
                    date_fin={projet1.dateFin}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {/* ROW 3 */}
          {activites1 &&
            activites1.map((activite, index) => (
              <Grid container spacing={0} marginBottom="20px">
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Avancement {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartAvancement
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartAvancement>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Dépense {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartDepense
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartDepense>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}

          {projet2 && (
            <Grid container spacing={2} marginBottom="20px">
              {/* ROW 2 */}
              <Grid item xs={12} backgroundColor={colors.primary[400]}>
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      {projet2.titre}
                    </Typography>
                  </Box>
                  <Box>
                    {projet2.imagePath && (
                      <Tooltip title="Image de l'avancement">
                        <a
                          href={`https://vincentlor-dev.fr/images_suiviprojet/${projet2.imagePath}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadOutlinedIcon
                            sx={{
                              fontSize: "26px",
                              color: colors.greenAccent[500],
                            }}
                          />
                        </a>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Box height="250px" m="-20px 0 0 0">
                  <LineChart
                    isDashboard={true}
                    projet_id={projet2.documentId}
                    date_debut={projet2.dateDebut}
                    date_fin={projet2.dateFin}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {/* ROW 3 */}
          {activites2 &&
            activites2.map((activite, index) => (
              <Grid container spacing={0} marginBottom="20px">
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Avancement {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartAvancement
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartAvancement>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    marginRight="10px"
                    padding="10px"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ marginBottom: "15px" }}
                    >
                      Dépense {activite.titre}
                    </Typography>
                    <Box height="250px" mt="0px">
                      <BarChartDepense
                        isDashboard={true}
                        activite_id={activite.documentId}
                      ></BarChartDepense>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Box>
      </main>
    </div>
  );
};

export default Dashboard;
