import axios from "axios";
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  useMediaQuery,
} from "@mui/material";
import Header from "../../components/Header";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import AddchartIcon from "@mui/icons-material/Addchart";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { Formik } from "formik";
import * as yup from "yup";
import Dropzone from "react-dropzone";

const checkoutSchemaAvancement = yup.object().shape({
  avancement: yup.string().required("Champ requis"),
  date: yup.string().required("Champ requis"),
});

const initialValuesAvancement = {
  avancement: "",
  date: "",
  timestamp: new Date().toISOString(),
};

const checkoutSchemaImage = yup.object().shape({});

const initialValuesImage = {
  timestamp: new Date().toISOString(),
};

const Donnees = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAvancement, setOpenAvancement] = useState(false);
  const handleOpenAvancement = () => setOpenAvancement(true);
  const handleCloseAvancement = () => setOpenAvancement(false);

  const [openImage, setOpenImage] = useState(false);
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);
  const [projet_id, setProjet_id] = useState("");
  const [myCell, setMyCell] = useState(null);

  const [projets, setProjets] = useState(null);

  const [isSidebar, setIsSidebar] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://vincentlor-dev.fr/server/projets"
      );

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        formattedArray.push(formattedData);
      });

      setProjets(formattedArray);
    }
    fetchData();
  }, []);

  const onCellClick = (cell) => {
    if (
      cell.field === "titre" ||
      cell.field === "dateDebut" ||
      cell.field === "dateFin" ||
      cell.field === "budget"
    ) {
      navigate({
        pathname: "/donnees/activites/",
        search: createSearchParams({
          titre: cell.row.titre,
          id: cell.row.documentId,
        }).toString(),
      });
    }

    if (cell.field === "avancement") {
      setProjet_id(cell.row.documentId);
      handleOpenAvancement();
    }

    if (cell.field === "image") {
      setProjet_id(cell.row.documentId);
      setMyCell(cell);
      handleOpenImage();
    }
  };

  const columns = [
    { field: "documentId", headerName: "ID" },
    { field: "description", headerName: "Description" },
    {
      field: "titre",
      headerName: "Titre",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "dateDebut", headerName: "Date de début", flex: 1 },
    { field: "dateFin", headerName: "Date de fin", flex: 1 },
    { field: "budget", headerName: "Budget", flex: 1 },
    {
      field: "avancement",
      headerName: "Avancement",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button variant="contained">
            <AddchartIcon />
          </Button>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button variant="contained">
            <AddPhotoAlternateIcon />
          </Button>
        );
      },
    },
  ];

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [errorMessage, setErrorMessage] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const avancement = async (values, onSubmitProps) => {
    const formDataAvancement = {
      avancement: values.avancement,
      date: values.date,
      projet_id: projet_id,
    };

    const response = await axios.post(
      "https://vincentlor-dev.fr/server/data_avancement_projet",
      {
        data: formDataAvancement,
      }
    );
    const success = response.status === 200;
    if (success) {
      handleCloseAvancement();
    } else {
      setErrorMessage("Erreur lors de la validation");
      animate();
    }
  };

  const handleFormSubmitAvancement = async (values, onSubmitProps) => {
    await avancement(values, onSubmitProps);
  };

  const image = async (values, onSubmitProps) => {
    const formData = new FormData();

    formData.append("titre", myCell.row.titre);
    formData.append("budget", myCell.row.budget);
    formData.append("dateFin", myCell.row.dateFin);
    formData.append("dateDebut", myCell.row.dateDebut);
    formData.append("description", myCell.row.description);
    if (values.image[0]) {
      formData.append("image", values.image[0]);
      formData.append("imagePath", values.image[0].name);
    }

    const response = await fetch(
      "https://vincentlor-dev.fr/server/projets/addimage/" + projet_id,
      {
        method: "POST",
        body: formData,
      }
    );
    const savedPost = await response.json();
    if (savedPost) {
      handleCloseImage();
    } else {
      setErrorMessage("Erreur lors de la validation");
      animate();
    }
  };

  const handleFormSubmitImage = async (values, onSubmitProps) => {
    await image(values, onSubmitProps);
  };

  if (!projets || projets.length === 0) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="Données des Projets"
              subtitle="Liste des projets"
            ></Header>
            <Box display="flex" justifyContent="end" mb="20px"></Box>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="Données des Projets"
              subtitle="Liste des projets"
            ></Header>
            <Box display="flex" justifyContent="end" mb="20px"></Box>
            <Box
              m="40px 0 0 0"
              height="70vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
            >
              <DataGrid
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      documentId: false,
                      description: false,
                    },
                  },
                }}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                rows={projets}
                getRowId={(row) => row.documentId}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                onCellClick={(cell) => {
                  onCellClick(cell);
                }}
              />
              {/* MODAL AVANCEMENT */}
              <Modal
                open={openAvancement}
                onClose={handleCloseAvancement}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Formik
                    onSubmit={handleFormSubmitAvancement}
                    initialValues={initialValuesAvancement}
                    validationSchema={checkoutSchemaAvancement}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            "& > div": {
                              gridColumn: isNonMobile ? undefined : "span 4",
                            },
                          }}
                        >
                          {errorMessage && (
                            <Typography
                              fontWeight="500"
                              fontSize="14px"
                              color="red"
                              sx={{
                                gridColumn: "span 4",
                              }}
                              className={shake ? `shake` : null}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                          <Typography>Avancement du projet</Typography>
                          <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            min="0"
                            max="100"
                            label="Avancement (%)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.avancement}
                            name="avancement"
                            error={!!touched.avancement && !!errors.avancement}
                            helperText={touched.avancement && errors.avancement}
                            sx={{ gridColumn: "span 4" }}
                          />

                          <TextField
                            fullWidth
                            variant="filled"
                            type="date"
                            label="Date"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="date"
                            value={values.date}
                            error={!!touched.date && !!errors.date}
                            helperText={touched.date && errors.date}
                            sx={{ gridColumn: "span 4" }}
                          />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            Valider
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Modal>

              {/* MODAL UPLOAD IMAGE */}
              <Modal
                open={openImage}
                onClose={handleCloseImage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Formik
                    onSubmit={handleFormSubmitImage}
                    initialValues={initialValuesImage}
                    validationSchema={checkoutSchemaImage}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            "& > div": {
                              gridColumn: isNonMobile ? undefined : "span 4",
                            },
                          }}
                        >
                          {errorMessage && (
                            <Typography
                              fontWeight="500"
                              fontSize="14px"
                              color="red"
                              sx={{
                                gridColumn: "span 4",
                              }}
                              className={shake ? `shake` : null}
                            >
                              {errorMessage}
                            </Typography>
                          )}
                          <Typography>
                            Téléverser une image de l'avancement
                          </Typography>

                          <Box
                            gridColumn="span 4"
                            border={`1px solid ${theme.palette.grey[100]}`}
                            borderRadius="5px"
                            p="1rem"
                            backgroundColor="#f0f0f0"
                            mt="50px"
                          >
                            <Dropzone
                              acceptedFiles=".jpg,.jpeg,.png"
                              minSize={0}
                              onDrop={(acceptedFiles) =>
                                setFieldValue("image", acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <Box
                                  {...getRootProps()}
                                  border={`2px dashed ${theme.palette.primary[300]}`}
                                  p="1rem"
                                  sx={{ "&:hover": { cursor: "pointer" } }}
                                >
                                  <input {...getInputProps()} />
                                  {!values.image ? (
                                    <p>Ajouter une image</p>
                                  ) : (
                                    <Box>
                                      <Typography>
                                        {values.image[0].name}
                                      </Typography>

                                      <EditOutlinedIcon />
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Dropzone>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            Valider
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Modal>
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default Donnees;
