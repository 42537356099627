import {
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";

const checkoutSchema = yup.object().shape({
  titre: yup.string().required("Champ requis"),
  description: yup.string().required("Champ requis"),
  dateDebut: yup.string().required("Champ requis"),
  dateFin: yup.string().required("Champ requis"),
});

const initialValues = {
  titre: "",
  description: "",
  dateDebut: "",
  dateFin: "",
  timestamp: new Date().toISOString(),
};

var initialValuesEdit = {};

const ActivitesUpdate = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [hasAlert, setHasAlert] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let editMode = searchParams.get("editMode");
  let id = searchParams.get("id");
  let projet_id = searchParams.get("projet_id");
  let titre = searchParams.get("titre");

  if (editMode === "true") {
    editMode = true;
  } else {
    editMode = false;
  }

  const [shake, setShake] = useState(false);

  const onChangeCheckBox = (e) => {
    setHasAlert(e.target.checked);
  };

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const activite = async (values, onSubmitProps) => {
    const formDataActivite = {
      titre: values.titre,
      description: values.description,
      dateDebut: values.dateDebut,
      dateFin: values.dateFin,
      projet_id: projet_id,
      alerte: values.alert,
    };

    if (!editMode) {
      const response = await axios.post(
        "https://vincentlor-dev.fr/server/activites",
        {
          data: formDataActivite,
        }
      );
      const success = response.status === 200;
      if (success) {
        navigate({
          pathname: "/activites/" + projet_id,
          search: createSearchParams({
            titre: titre,
            id: projet_id,
          }).toString(),
        });
      } else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    } else {
      const response = await axios.put(
        `https://vincentlor-dev.fr/server/activites/${id}`,
        {
          data: formDataActivite,
        }
      );
      const success = response.status === 200;
      if (success) {
        navigate({
          pathname: "/activites/" + projet_id,
          search: createSearchParams({
            titre: titre,
            id: projet_id,
          }).toString(),
        });
      } else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await activite(values, onSubmitProps);
  };

  const fetchData = async () => {
    const response = await axios
      .get(`https://vincentlor-dev.fr/server/activites/getone/${id}`)
      .then((response) => {
        setFormData(response.data.data);
        initialValuesEdit = {
          titre: response.data.data.titre,
          description: response.data.data.description,
          dateDebut: response.data.data.dateDebut,
          dateFin: response.data.data.dateFin,
          alert: response.data.data.alerte,
        };
        if (response.data.data.alerte) {
          setHasAlert(true);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editMode) {
      fetchData();
    }
  }, []);

  if (isLoading && editMode) {
    return <div className="app">Chargement...</div>;
  }

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header
            title={editMode ? "Modifier l'activité" : "Créer une activité"}
            subtitle={
              editMode
                ? "Modifier l'activité existante (projet : " + titre + ")"
                : "Créer une nouvelle activité (projet : " + titre + ")"
            }
          />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={editMode ? initialValuesEdit : initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Titre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.titre}
                    name="titre"
                    error={!!touched.titre && !!errors.titre}
                    helperText={touched.titre && errors.titre}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="description"
                    value={values.description}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date de début"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="dateDebut"
                    value={values.dateDebut}
                    error={!!touched.dateDebut && !!errors.dateDebut}
                    helperText={touched.dateDebut && errors.dateDebut}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date de fin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="dateFin"
                    value={values.dateFin}
                    error={!!touched.dateFin && !!errors.dateFin}
                    helperText={touched.dateFin && errors.dateFin}
                    sx={{ gridColumn: "span 4" }}
                  />


                  <div>
                    <input
                      type="checkbox"
                      checked={hasAlert}
                      onChange={onChangeCheckBox}
                      label="Alerte"
                    />{" "}
                    Alerte
                  </div>
                  {hasAlert && (
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      min="0"
                      max="100"
                      label="Alerte (%)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="alert"
                      value={values.alert}
                      error={!!touched.alert && !!errors.alert}
                      helperText={touched.alert && errors.alert}
                      sx={{ gridColumn: "span 4" }}
                    />
                  )}
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Valider
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </main>
    </div>
  );
};

export default ActivitesUpdate;
