import { Route, Routes } from "react-router-dom";
//import Dashboard from './pages/Dashboard'
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import Bar from "./scenes/bar";
import Projets from "./scenes/projets";
import Activites from "./scenes/activites";
import ProjetsAdd from "./scenes/projets/ProjetsAdd";
import ProjetsUpdate from "./scenes/projets/ProjetsUpdate";
import ActivitesAdd from "./scenes/activites/ActivitesAdd";
import ActivitesUpdate from "./scenes/activites/ActivitesUpdate";
import Donnees from "./scenes/donnees";
import DonneesActivites from "./scenes/donnees/DonneesActivites";
import DonneesAvancementAdd from "./scenes/donnees/DonneesAvancementAdd";
import DonneesDepenseAdd from "./scenes/donnees/DonneesDepenseAdd";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/projets" element={<Projets />} />
            <Route path="/projets/new" element={<ProjetsAdd />} />
            <Route path="/projets/update" element={<ProjetsUpdate />} />
            <Route path="/activites/:id" element={<Activites />} />
            <Route path="/activites/new/" element={<ActivitesAdd />} />
            <Route path="/activites/update/" element={<ActivitesUpdate />} />
            <Route path="/donnees" element={<Donnees />} />
            <Route path="/donnees/activites" element={<DonneesActivites />} />
            <Route path="/donnees/activites/depense" element={<DonneesDepenseAdd />} />
            <Route path="/donnees/activites/avancement" element={<DonneesAvancementAdd />} />
            <Route path="/bar" element={<Bar />} />
          </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
