import axios from "axios";
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import AddchartIcon from "@mui/icons-material/Addchart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";

const DonneesActivites = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activites, setActivites] = useState(null);

  const [isSidebar, setIsSidebar] = useState(true);
  const [searchParams] = useSearchParams();

  let titre = searchParams.get("titre");
  let projet_id = searchParams.get("id");

  const navigate = useNavigate();

  function compareByDate(a, b) {
    if (a.dateDebut < b.dateDebut) {
      return -1;
    }
    if (a.dateDebut > b.dateDebut) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://vincentlor-dev.fr/server/activites/" + projet_id
      );

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        formattedArray.push(formattedData);
      });

      const d = formattedArray.sort(compareByDate)
      setActivites(d);
    }
    fetchData();
  }, []);

  const columns = [
    { field: "documentId", headerName: "ID" },
    {
      field: "titre",
      headerName: "Titre",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "dateDebut", headerName: "Date de début", flex: 1 },
    { field: "dateFin", headerName: "Date de fin", flex: 1 },
    {
      field: "avancement",
      headerName: "Avancement",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onButtonAddAvancement(e, params.row)}
            variant="contained"
          >
            <AddchartIcon />
          </Button>
        );
      },
    },
    {
      field: "depense",
      headerName: "Dépense",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onButtonAddDepense(e, params.row)}
            variant="contained"
          >
            <AddShoppingCartIcon />
          </Button>
        );
      },
    },
  ];

  const onButtonAddDepense = (e, row) => {
    navigate({
      pathname: "/donnees/activites/depense",
      search: createSearchParams({
        titre: row.titre,
        activite_id: row.documentId,
        projet_id: projet_id,
      }).toString(),
    });
  };

  const onButtonAddAvancement = async (e, row) => {
    navigate({
      pathname: "/donnees/activites/avancement",
      search: createSearchParams({
        titre: row.titre,
        activite_id: row.documentId,
        projet_id: projet_id,
      }).toString(),
    });
  };

  if (!activites || activites.length === 0) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title={`Activités du projet : ${titre}`}
              subtitle="Liste des activités"
            ></Header>
            <Box display="flex" justifyContent="end" mb="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate({
                    pathname: "/activites/new/",
                    search: createSearchParams({
                      titre: titre,
                      id: projet_id,
                    }).toString(),
                  });
                }}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Nouvel Activité
              </Button>
            </Box>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title={`Activités du projet : ${titre}`}
              subtitle="Liste des activités"
            ></Header>
            <Box display="flex" justifyContent="end" mb="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate({
                    pathname: "/activites/new/",
                    search: createSearchParams({
                      titre: titre,
                      id: projet_id,
                    }).toString(),
                  });
                }}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Nouvel Activite
              </Button>
            </Box>
            <Box
              m="40px 0 0 0"
              height="70vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      documentId: false,
                    },
                  },
                }}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                rows={activites}
                getRowId={(row) => row.documentId}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default DonneesActivites;
