import {
    Box,
    Typography,
    useMediaQuery,
    TextField,
    Button,
  } from "@mui/material";
  import { Formik } from "formik";
  import * as yup from "yup";
  import { useState, useEffect } from "react";
  import { useNavigate, useSearchParams } from "react-router-dom";
  import axios from "axios";
  import Header from "../../components/Header";
  import Sidebar from "../global/Sidebar";
  import Topbar from "../global/Topbar";
  
  const checkoutSchema = yup.object().shape({
    titre: yup.string().required("Champ requis"),
    description: yup.string().required("Champ requis"),
    dateDebut: yup.string().required("Champ requis"),
    dateFin: yup.string().required("Champ requis"),
    budget: yup.string().required("Champ requis"),
  });
  
  const initialValues = {
    titre: "",
    description: "",
    dateDebut: "",
    dateFin: "1",
    budget: "0",
    timestamp: new Date().toISOString(),
  };
  
  var initialValuesEdit = {};
  
  const ProjetsUpdate = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isSidebar, setIsSidebar] = useState(true);
    const [formData, setFormData] = useState({});
    const [isLoading, setLoading] = useState(true);
  
    const [searchParams] = useSearchParams();
  
    const isNonMobile = useMediaQuery("(min-width:600px)");
  
    const navigate = useNavigate();
    
    let editMode = searchParams.get("editMode");
    let id = searchParams.get("id");
   

    if (editMode === "true") {
      editMode = true;
    } else {
      editMode = false;
    }
  
  
    const [shake, setShake] = useState(false);
  
    const animate = () => {
      // Button begins to shake
      setShake(true);
  
      // Buttons stops to shake after 2 seconds
      setTimeout(() => setShake(false), 1000);
    };
  



  
    const projet = async (values, onSubmitProps) => {
      // this allows us to send form info with image
      const formDataProjet = {
        titre: values.titre,
        description: values.description,
        dateDebut: values.dateDebut,
        dateFin: values.dateFin,
        budget: values.budget,
      };
  
      if (!editMode) {
        const response = await axios.post("https://vincentlor-dev.fr/server/projets", {
          data: formDataProjet,
        });
        const success = response.status === 200;
        if (success) {
          navigate("/projets");
        } else {
          setErrorMessage("Erreur lors de la validation");
          animate();
        }
      } else {
        const response = await axios.put(`https://vincentlor-dev.fr/server/projets/${id}`, {
          data: formDataProjet,
        });
        const success = response.status === 200;
        if (success) {
          navigate("/projets");
        } else {
          setErrorMessage("Erreur lors de la validation");
          animate();
        }
      }
    };
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      await projet(values, onSubmitProps);
    };
  
    const fetchData = async () => {
      const response = await axios
        .get(`https://vincentlor-dev.fr/server/projets/${id}`)
        .then((response) => {
          setFormData(response.data.data);
          initialValuesEdit = {
            titre: response.data.data.titre,
            description: response.data.data.description,
            dateDebut: response.data.data.dateDebut,
            dateFin: response.data.data.dateFin,
            budget: response.data.data.budget,
          };
          setLoading(false);
        });
    };
  
    useEffect(() => {
      if (editMode) {
        fetchData();
      }
    }, []);
  
    if (isLoading && editMode) {
      return <div className="app">Chargement...</div>;
    }
  
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title={editMode ? "Modifier le projet" : "Créer un projet"}
              subtitle={
                editMode
                  ? "Modifier le projet existant"
                  : "Créer un nouveau projet"
              }
            />
  
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={editMode ? initialValuesEdit : initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    {errorMessage && (
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        color="red"
                        sx={{
                          gridColumn: "span 4",
                        }}
                        className={shake ? `shake` : null}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Titre"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.titre}
                      name="titre"
                      error={!!touched.titre && !!errors.titre}
                      helperText={touched.titre && errors.titre}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="description"
                      value={values.description}
                      error={!!touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                      sx={{ gridColumn: "span 4" }}
                    />
  
                    <TextField
                      fullWidth
                      variant="filled"
                      type="date"
                      label="Date de début"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="dateDebut"
                      value={values.dateDebut}
                      error={!!touched.dateDebut && !!errors.dateDebut}
                      helperText={touched.dateDebut && errors.dateDebut}
                      sx={{ gridColumn: "span 4" }}
                    />
  
                    <TextField
                      fullWidth
                      variant="filled"
                      type="date"
                      label="Date de fin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="dateFin"
                      value={values.dateFin}
                      error={!!touched.dateFin && !!errors.dateFin}
                      helperText={touched.dateFin && errors.dateFin}
                      sx={{ gridColumn: "span 4" }}
                    />
  
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Budget"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="budget"
                      value={values.budget}
                      error={!!touched.budget && !!errors.budget}
                      helperText={touched.budget && errors.budget}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Valider
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </main>
      </div>
    );
  };
  
  export default ProjetsUpdate;
  