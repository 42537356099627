import axios from "axios";
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Box, Button } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EngineeringIcon from '@mui/icons-material/Engineering';

const Projets = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [projets, setProjets] = useState(null);

  const [isSidebar, setIsSidebar] = useState(true);

  const navigate = useNavigate();

  async function fetchData2() {
    const response = await axios.get("https://vincentlor-dev.fr/server/projets");

    const dataObject = response.data.data;

    const arrayOfKeys = Object.keys(dataObject);
    const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
    const formattedArray = [];
    arrayOfKeys.forEach((key, index) => {
      const formattedData = { ...arrayOfData[index] };
      formattedData["documentId"] = key;
      formattedArray.push(formattedData);
    });

    setProjets(formattedArray);
  }


  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://vincentlor-dev.fr/server/projets");

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        formattedArray.push(formattedData);
      });

      setProjets(formattedArray);
    }
    fetchData();
  }, []);

  const onButtonActiviteClick = (e, row) => {
    navigate({
      pathname: "/activites/"+row.documentId,
      search: createSearchParams({
        titre : row.titre,
        id: row.documentId,
      }).toString(),
    });
  };

  const onButtonEditClick = (e, row) => {
    navigate({
      pathname: "/projets/update",
      search: createSearchParams({
        editMode: true,
        id: row.documentId,
      }).toString(),
    });
  };

  const onButtonDeleteClick = async (e, row) => {
    if (
      window.confirm(
        "Souhaitez vous réellement supprimer le projet " + row.titre + " ?"
      )
    ) {
      const response = await fetch(
        `https://vincentlor-dev.fr/server/projets/${row.documentId}`,
        {
          method: "DELETE",
        }
      );

      const success = response.status === 200;
      if (success) {
        //window.location.reload(false);
        fetchData2();
      } else {
        alert("Erreur lors de la suppression. Veuillez réessayer.");
      }
    }
  };

  const columns = [
    { field: "documentId", headerName: "ID" },
    {
      field: "titre",
      headerName: "Titre",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "dateDebut", headerName: "Date de début", flex: 1 },
    { field: "dateFin", headerName: "Date de fin", flex: 1 },
    { field: "budget", headerName: "Budget", flex: 1 },
    {
      field: "activites",
      headerName: "Activités",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onButtonActiviteClick(e, params.row)}
            variant="contained"
          >
            <EngineeringIcon />
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "Modifier",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onButtonEditClick(e, params.row)}
            variant="contained"
          >
            <EditIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Supprimer",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onButtonDeleteClick(e, params.row)}
            variant="contained"
          >
            <DeleteForeverIcon />
          </Button>
        );
      },
    },
  ];

  if (!projets || projets.length === 0) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header title="Projets" subtitle="Liste des projets"></Header>
            <Box display="flex" justifyContent="end" mb="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate("/projets/new");
                }}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Nouveau Projet
              </Button>
            </Box>
            <Box
              m="40px 0 0 0"
              height="70vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            ></Box>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header title="Projets" subtitle="Liste des projets"></Header>
            <Box display="flex" justifyContent="end" mb="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate("/projets/new");
                }}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Nouveau Projet
              </Button>
            </Box>
            <Box
              m="40px 0 0 0"
              height="70vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      documentId: false,
                    },
                  },
                }}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                rows={projets}
                getRowId={(row) => row.documentId}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default Projets;
