import { Box, IconButton, useTheme, Tooltip } from "@mui/material"
import { useContext } from "react";
import { ColorModeContext,tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon  from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon  from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon  from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon  from "@mui/icons-material/PersonOutlined";
import SearchIcon  from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext);

    const navigate = useNavigate();

    return <Box display="flex" justifyContent="space-between" p={2}>
        {/*Search Bar */}
        <Box 
        display="flex" 
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        >

{/* <InputBase sx={{ml:2, flex:1}} placeholder="Rechercher"></InputBase>
        <IconButton type="button" sx={{ p:1}}>
            <SearchIcon />
        </IconButton> */}
        </Box>

        {/* ICONS */}  
        <Box display="flex">
      {/*   <Tooltip title="Thème">
            <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === 'dark' ? (
                     <DarkModeOutlinedIcon/>
                ) : ( 
                <LightModeOutlinedIcon/> 
                )}
    
            </IconButton>
            </Tooltip> */}

       {/*      <Tooltip title="Notifications">
            <IconButton>
                <NotificationsOutlinedIcon/>
            </IconButton>
            </Tooltip> */}

            <Tooltip title="Se déconnecter">
            <IconButton  onClick={() => {
                        Cookies.remove('jwt', { path: '/', domain: 'crm.vincentlor-dev.fr' })
                      navigate("/");
                    }}>
                <PersonOutlinedIcon/>
            </IconButton>
            </Tooltip>
        </Box>
    </Box>
}

export default Topbar;