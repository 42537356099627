import { Box } from "@mui/material";
import Header from "../../components/Header";
import BarChartAvancement from "../../components/BarChartAvancement";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState } from "react";

const Bar = () => {
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header
            title="Graphique en barre"
            subtitle="Exemple d'un graphique en barre"
          ></Header>
          <Box height="75vh">
            <BarChartAvancement></BarChartAvancement>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Bar;
