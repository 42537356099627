import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import axios from "axios";
import { useEffect, useState } from "react";
import { timeFormat } from 'd3-time-format';
import { scaleTime } from 'd3-scale';
import { useMemo } from "react";

const BarChartAvancement = ({ isDashboard = false, activite_id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);

/*   const formatter = timeFormat('%d %b')
const timeScaleTicks = useMemo(() => {
    const scale = scaleTime().domain([from, to])
    const ticks = scale.ticks(10)
    return ticks.map(tick => (
      formatter(tick)
    ))
  }) */

  var monthNames = ["Jan", "Feb", "Mar","Apr", "May", "Jun",
 "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  function formatter(val){
    var t  = new Date(val);
    return t.getDate()+' '+monthNames[t.getMonth()]+' '+t.getFullYear();
  }

  function compareByDate(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://vincentlor-dev.fr/server/data_avancement_activite/"+activite_id
      );

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        formattedArray.push(formattedData);
      });

      

      const d = formattedArray.sort(compareByDate);
      setData(d);
      setLoading(false);
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <ResponsiveBar
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      keys={["avancement", "norme"]}
      indexBy="date"
      groupMode="grouped"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={['#98bad5', '#304674']}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
     /*  axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        format: (val) => { 
            return timeScaleTicks.includes(formatter(new Date(val))) ? formatter(new Date(val)) : '' 
        } 
      }} */
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legend: isDashboard ? undefined : "Date",
        legendPosition: "middle",
        legendOffset: 32,
        format:(val) => {
          return formatter(val);
        }
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Avancement",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      tooltip={(e)=>{return (e.formattedValue+'%')}}
      role="application"
      ariaLabel="Graphique barre"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " en : " + e.indexValue;
      }}
    />
  );
};

export default BarChartAvancement;
