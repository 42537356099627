import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useEffect, useState } from "react";
import axios from "axios";

const LineChart = ({
  isDashboard = false,
  projet_id,
  date_debut = "2023-12-01",
  date_fin = "2023-12-31",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dataPlan, setDataPlan] = useState(null);
  const [dataReal, setDataReal] = useState(null);

  const ex = [
    {
      id: "Planifié",
      color: "#98bad5",
      data: [
        {
          x: "2023-12-01",
          y: 0,
        },
      ],
    },
    {
      id: "Réalisé",
      color: "#304674",
      data: [
        {
          x: "2023-12-01",
          y: 0,
        },
      ],
    },
  ];

  const [example, setExample] = useState(ex);

  function compareByDate(a, b) {
    if (a.x < b.x) {
      return -1;
    }
    if (a.x > b.x) {
      return 1;
    }
    return 0;
  }


  const  getDataRealise = async (id) => {
    const response = await axios.get(
      "https://vincentlor-dev.fr/server/data_avancement_projet/" + projet_id
    );

    const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      let formattedData = {
        x: date_debut,
        y: 0,
      };
      formattedArray.push(formattedData);

      arrayOfKeys.forEach((key, index) => {
        //const formattedData = { ...arrayOfData[index] };
          let formattedData = {
            x: arrayOfData[index]["date"],
            y: arrayOfData[index]["avancement"],
          };
          formattedArray.push(formattedData);
      
    
      });

      const d = formattedArray.sort(compareByDate);
      
      return d;

  }

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://vincentlor-dev.fr/server/activites/" + projet_id
      );

      
      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      let formattedData = {
        x: date_debut,
        y: 0,
      };
      formattedArray.push(formattedData);

      arrayOfKeys.forEach((key, index) => {
        //const formattedData = { ...arrayOfData[index] };
        if (arrayOfData[index]["alerte"]) {
          let formattedData = {
            x: arrayOfData[index]["dateFin"],
            y: arrayOfData[index]["alerte"],
          };
          formattedArray.push(formattedData);
        }
    
      });

      formattedData = {
        x: date_fin,
        y: 100,
      };
      formattedArray.push(formattedData);

      const d = formattedArray.sort(compareByDate);

      const dataRealise = await getDataRealise(projet_id);

      const e = [
        {
          id: "Planifié",
          color: "#98bad5",
          data: d,
        },
        {
          id: "Réalisé",
          color: "#304674",
          data: dataRealise
        },
      ];

      setExample(e);
    }
    fetchData();
  }, []);

  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }, index) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={
          index % 2 === 0
            ? {
                // simulate line will dash stroke when index is even
                strokeDasharray: "3, 6",
                strokeWidth: 3,
              }
            : {
                // simulate line with solid stroke
                strokeWidth: 1,
              }
        }
      />
    ));
  };

  return (
    <ResponsiveLine
      data={example}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
      }}
      xFormat="time:%d %b"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickValues: "every 24 hours",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legendOffset: -12,
        legendPosition: "middle",
        format: "%d %b",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "count", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      layers={[
        // includes all default layers
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "line",
        "slices",
        "points",
        "mesh",
        "legends",
        DashedSolidLine, // add the custome layer here
      ]}
    />
  );
};

export default LineChart;
